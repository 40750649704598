<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')"
            >
              Compose
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in emailFilters"
                :key="filter.title + $route.path"
                :active="isDynamicRouteActive(filter.route)"
                :to="filter.route"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  class="mr-75"
                  size="18"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                <b-badge
                  v-if="emailsMeta[filter.title.toLowerCase()]"
                  :variant="resolveFilterBadgeColor(filter.title)"
                  class="float-right"
                  pill
                >
                  {{ emailsMeta[ filter.title.toLowerCase() ] }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <h6 class="section-label mt-3 mb-1 px-2">
              Labels
            </h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :active="isDynamicRouteActive(label.route)"
                :to="label.route"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  :class="`bullet-${label.color}`"
                  class="bullet bullet-sm mr-1"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isDynamicRouteActive } from '@core/utils/utils'
import {
  BBadge, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailsMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const emailFilters = [
      {
        title: 'Inbox',
        icon: 'MailIcon',
        route: { name: 'apps-email' },
      }, {
        title: 'Sent',
        icon: 'SendIcon',
        route: {
          name: 'apps-email-folder',
          params: { folder: 'sent' },
        },
      }, {
        title: 'Draft',
        icon: 'Edit2Icon',
        route: {
          name: 'apps-email-folder',
          params: { folder: 'draft' },
        },
      }, {
        title: 'Starred',
        icon: 'StarIcon',
        route: {
          name: 'apps-email-folder',
          params: { folder: 'starred' },
        },
      }, {
        title: 'Spam',
        icon: 'InfoIcon',
        route: {
          name: 'apps-email-folder',
          params: { folder: 'spam' },
        },
      }, {
        title: 'Trash',
        icon: 'TrashIcon',
        route: {
          name: 'apps-email-folder',
          params: { folder: 'trash' },
        },
      },
    ]

    const emailLabel = [
      {
        title: 'Personal',
        color: 'success',
        route: {
          name: 'apps-email-label',
          params: { label: 'personal' },
        },
      }, {
        title: 'Company',
        color: 'primary',
        route: {
          name: 'apps-email-label',
          params: { label: 'company' },
        },
      }, {
        title: 'Important',
        color: 'warning',
        route: {
          name: 'apps-email-label',
          params: { label: 'important' },
        },
      }, {
        title: 'Private',
        color: 'danger',
        route: {
          name: 'apps-email-label',
          params: { label: 'private' },
        },
      },
    ]

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Draft') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailFilters,
      emailLabel,
    }
  },
}
</script>

<style>

</style>
